import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditForm from './form'

const rootElement = document.getElementById('root')!

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`
const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(21 181 199);
  color: #fff;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus, &:active {
    opacity: 1;
    background-color: rgb(21 181 199) !important;
    color: #fafafa !important;
  }
`

const Edit: React.FC<any> = ({ onSubmit }) => {

  //onEditClick
  const [show, setShow] = React.useState(false)
  const onEditClick = () => {
    setShow(prev => !prev)
  }

  return (
    <>
      {show &&
        <ProtalWrap
          onEditClick={onEditClick}
          onSubmit={onSubmit}
        />
      }
      <EditButton className="btn" onClick={onEditClick}>
        <FontAwesomeIcon icon="pen" />
      </EditButton>
    </>
  )
}

export default Edit

const ProtalWrap: React.FC<any> = ({ onEditClick, onSubmit }) => {


  return ReactDOM.createPortal(
    <Wrap>
      <Nav>
        <FontAwesomeIcon icon="arrow-left" onClick={onEditClick} />
      </Nav>
      <EditBody>
        <EditForm onSubmit={onSubmit} />
      </EditBody>
    </Wrap>
    , rootElement)
}

const Nav = styled.div`
  position: fixed;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  padding: 15px;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
`
const EditBody = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 15px
`