import React from 'react'

type SysNavType = {
  name: string
}
/**
 *  SysNav
 * @param props 
 * @returns 
 */
const SysNav: React.FC<SysNavType> = ({ name }) => {
  return (
    <div className="p-3 text-center">
      <h5 className='mb-0'>{name}</h5>
    </div>
  )
}

export default SysNav