import React from 'react'
import { Budget } from 'types/budget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type TotalBudgetType = {
  data: Budget
}
/**
 * TotalBudget
 * @param props 
 * @returns 
 */
const TotalBudget: React.FC<TotalBudgetType> = ({
  data: { budget, spent, remaining },
  ...prop
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <FontAwesomeIcon icon="wallet" className='mr-2' />
          <p className='mb-0'>NT$ {budget}</p>
        </div>
        <div className="col-4">
          <FontAwesomeIcon icon="cart-shopping" className='mr-2' />
          <p className='mb-0'>NT$ {spent}</p>
        </div>
        <div className="col-4">
          <FontAwesomeIcon icon="scale-balanced" className='mr-2' />
          <p className='mb-0'>NT$ {remaining}</p>
        </div>
      </div>
    </div>
  )
}

export default TotalBudget