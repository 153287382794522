import React from 'react'
import SpentBlock from './SpentBlock'
import { TypeSpentBlock } from './types';
import styled from '@emotion/styled'

const Wrap = styled.div`
  height: calc( 100% - 236px);
  padding: 0 10px 50px;
  overflow-y: scroll;
`

type TypeTodaySpent = {
  data?: TypeSpentBlock[] | [] | undefined
}

const TodaySpent: React.FC<TypeTodaySpent> = ({ data = [] }) => {
  return (
    <Wrap>
      {data.map((item, index) =>
        <SpentBlock
          key={index}
          data={item}
          idx={index}
        />
      )}
    </Wrap>
  )
}

export default TodaySpent