import React from 'react'
import { TypeSpentItem } from './types'
import { CurrencMoney } from 'components/CurrencMoney'

type SpentItemProp = {
  data: TypeSpentItem
}

const SpentItem: React.FC<SpentItemProp> = ({ data }) => {

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p>{data.Name}</p>
        <CurrencMoney value={data.Price} />
      </div>

    </div>
  )
}

export default SpentItem