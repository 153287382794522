import React from 'react'
import { useForm } from 'react-hook-form'

const EditForm: React.FC<any> = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        className='form-control'
        {...register("firstName")}
      />
      <button type="submit" className='btn btn-primary'>Submit</button>
    </form>
  )
}

export default EditForm