import React from 'react'
import styled from '@emotion/styled'
import { CurrencMoney } from 'components/CurrencMoney'

const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 50px;
`

const TextSmP = styled.p`
  font-size: 12px;
  `

type TypeCurrentBudget = {
  data: {
    todayBudget: number,
    todaySpent: number,
    todayRemain: number
  }
}

//Today's Budget
const CurrentBudget: React.FC<TypeCurrentBudget> = ({ data }) => {
  console.log(data);


  return (
    <Wrap>
      <div className="container-fluid h-100">
        <div className="row h-100" style={{ background: '#c6e0e3' }}>
          <div className="col-6 text-center py-1" style={{ borderRight: '1px solid #eee' }}>
            <TextSmP className="mb-0">SPENT</TextSmP>
            <CurrencMoney value={data.todaySpent} />
          </div>
          <div className="col text-center py-1">
            <TextSmP className="mb-0">REMAINING</TextSmP>
            <CurrencMoney value={data.todayRemain} />
          </div>
        </div>
      </div>
    </Wrap>
  )
}

export default CurrentBudget;
