import React from "react";
import SysNav from "./SysNav";
import TotalBudget from "./TotalBudget";
import CurrentBudget from "./CurrentBudget";
import DateNav from './DateNav'
import Spent from './Spent'
import { TypeSpentItem } from 'pages/Budget/Spent/types';
import Edit from 'pages/Budget/Edit'


const Index: React.FC = (props) => {

  //dataNavItemClickHandler
  const dataNavItemClickHandler = (date: Date) => {
    console.log(date);
  }

  //handleEditSubmit
  const handleEditSubmit = (data: any) => {
    //need get daliy data
    console.log(data);
  }

  return (
    <>
      <SysNav name={'USA_RoadTrip_2023'} />
      <TotalBudget data={fakeTB} />
      <DateNav
        data={fakeJSDateArray}
        onClick={dataNavItemClickHandler}
      />
      <Spent
        data={fakeDaily}
      />
      <CurrentBudget
        data={fakeCurrentBudget}
      />
      <Edit onSubmit={handleEditSubmit} />
    </>
  );
};

export default Index;

const fakeCurrentBudget = {
  todayBudget: 10000,
  todaySpent: 0,
  todayRemain: 100
}

const fakeTB = {
  budget: 600000,
  spent: 0,
  remaining: 600000,
};

const fakeJSDateArray = [
  new Date(2021, 4, 29),
  new Date(2021, 4, 30),
  new Date(2021, 5, 1),
  new Date(2021, 5, 2),
  new Date(2021, 5, 3),
  new Date(2021, 5, 4),
  new Date(2021, 5, 5),
  new Date(2021, 5, 6),
  new Date(2021, 5, 7),
  new Date(2021, 5, 8),
  new Date(2021, 5, 9),
  new Date(2021, 5, 10),
]

const fItem: TypeSpentItem = {
  Id: 1,
  Name: 'Hotel',
  Price: 1000,
  PayType: '1',
  SpentType: '1',
}

const fakeDaily = [
  {
    name: 'accommodation',
    budget: 200,
    items: [fItem]
  },
  {
    name: 'transport',
    budget: 200,
    items: [fItem, fItem]
  },
  {
    name: 'food',
    budget: 200,
    items: [fItem]
  },
  {
    name: 'sightseeing',
    budget: 200,
    items: [fItem, fItem, fItem, fItem, fItem, fItem]
  },
  {
    name: 'shopping',
    budget: 200,
    items: [fItem, fItem, fItem, fItem, fItem, fItem]
  },
  {
    name: 'Etc',
    budget: 200,
    items: []
  },
]

