import React from 'react'
// import DateDisplay from 'components/DateDisplay'
import Budget from 'pages/Budget'
import styled from '@emotion/styled'

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const HomePage: React.FC = () => {

  return (
    <Wrap>
      <Budget></Budget>
    </Wrap>
  )
}

export default HomePage
