import React from 'react'
import { getCurrenceSymbol } from 'libs/money'

type TypeCurrencMoney = {
  value: number
  currency?: string
  size?: number
  props?: any
}

export const CurrencMoney: React.FC<TypeCurrencMoney> = ({
  value = 0,
  currency,
  size
}) => {
  return (
    <p
      className='mb-0'
      style={{ fontSize: size ?? 16 }}
    >
      {getCurrenceSymbol(currency)} {value}
    </p>
  )
}
