import React from 'react'
import SpentItem from './SpentItem'
import { TypeSpentBlock } from './types'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CurrencMoney } from 'components/CurrencMoney';

const Title = styled.div`
  margin-bottom: 0;
  padding: 2px 10px;
  background-color: #f5f5f5;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
`

const ItemWrap = styled.div`
  padding: 15px;
`

type SpentBlockProp = {
  data: TypeSpentBlock,
  idx: number
}

const SpentBlock: React.FC<SpentBlockProp> = ({ data, idx }) => {

  return (
    <div className='mb-2'>
      <Title className='d-flex align-items-center justify-content-between'>
        <p className="mb-0">
          {getSpentBlockIcon(idx)}
          {data.name.toUpperCase()}
        </p>
        <CurrencMoney value={data.budget} size={12} />
      </Title>
      {
        data.items.length > 0
          ?
          <ItemWrap>
            {data.items.map((item, index) =>
              <SpentItem
                key={index}
                data={item}
              />
            )}
          </ItemWrap>
          : noContant()
      }
    </div>
  )
}


export default SpentBlock

const noContant = () => {
  return (
    <div className='p-3'>
      <p>no spent</p>
    </div>
  )
}

const getSpentBlockIcon = (idx: number) => {
  switch (idx) {
    case 0:
      return <FontAwesomeIcon icon='hotel' className='me-1' />
    case 1:
      return <FontAwesomeIcon icon='car' className='me-1' />
    case 2:
      return <FontAwesomeIcon icon='burger' className='me-1' />
    case 3:
      return <FontAwesomeIcon icon='location-dot' className='me-1' />
    case 4:
      return <FontAwesomeIcon icon='cart-shopping' className='me-1' />
    case 5:
      return <FontAwesomeIcon icon='circle' className='me-1' />
  }
}