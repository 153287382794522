export const getCurrenceSymbol = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return 'NT$';
  }
}