import React from 'react'

const DateNav: React.FC<TypeDateNav> = ({ data, onClick }) => {

  return (
    <div className='p-3 w-100'>
      <div className="container-fluid w-100" style={{ overflow: 'scroll' }}>
        <div className="row" style={{ flexWrap: 'nowrap' }}>
          {data.map((date, i) =>
            <DateNavItem key={i} date={date} onClick={onClick} />
          )}
        </div>
      </div>
    </div>
  )
}

export default DateNav

const DateNavItem: React.FC<TypeDateNavItem> = ({ date, onClick }) => {

  return (
    <div
      className='p-3 w-100 text-center'
      style={{ flex: 'auto', width: '100px', cursor: 'pointer' }}
      onClick={() => onClick && onClick(date)}
    >
      <p className="mb-1" style={{ fontSize: 12 }}>{getDayName(date)}</p>
      <p className='mb-1'>{date.getDate()}</p>
      <p className="mb-0" style={{ fontSize: 12 }}>{getMonthName(date)}</p>
    </div>
  )
}

//
function getMonthName(date: Date) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  ]
  return monthNames[date.getMonth() - 1]
}

function getDayName(date: Date) {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  return dayNames[date.getDay()]
}

type TypeDateNav = {
  data: Date[],
  onClick?: DateNavClickHandler
}

type TypeDateNavItem = {
  date: Date,
  onClick?: DateNavClickHandler
}

type DateNavClickHandler = (date: Date) => void